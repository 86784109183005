import React from "react"
import { mileageTrackingPage } from "../../Content/FeatureInnerPages"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import FeatureDetailsPage from "../../components/pages/FeatureDetails/FeatureDetailsPage"

const MileageTracking = () => {
  return (
    <Layout>
      <Seo
        title="Effortless Mileage Tracking with Synkli's Trip LogBook"
        description={`Log every business or personal trip automatically with Synkli's Trip LogBook, helping Australians boost tax claims and maximise business savings. Try it today!`}
      />

      <FeatureDetailsPage data={mileageTrackingPage} />
      
    </Layout>
  )
}

export default MileageTracking
